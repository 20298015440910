// jscs:disable
'use strict';
// generated by mdm:generate-frontend-constants on 2024-03-04T11:32:15.795117660
angular.module('metadatamanagementApp')
  .constant('ENV', 'dev')
  .constant('VERSION', '2023.2.1')
  .constant('ElasticSearchProperties', {
    apiVersion: '7.x',
    logLevel: 'error',
    pageSize: 10
  });
